import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CandidateEventTicket from "../models/CandidateEventTicket";
import CandidateService from "../services/CandidateService";
import { StyledTableCell, StyledTableRow } from "../theme/GlobalTheme";
import AppSpinner from "../components/AppSpinner";
import ConfirmationDialog from "../components/ConfirmationDialog";
import Paragraph from "../components/Paragraph";
import AppTextField from "../components/AppTextField";
import LoadingModal from "../components/LoadingModal";
import QRCode from "react-qr-code";

interface CandidateTicketGroup {
  eventTicket: CandidateEventTicket;
  ticketsId: string[];
}

export default function EventTicketScreen() {
  const [tickets, setTickets] = useState<CandidateTicketGroup[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState<string | JSX.Element>("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [eventModalOpen, setEventModalOpen] = useState<boolean>(false);
  const [eventQuantity, setEventQuantity] = useState<number>(1);
  const [eventTicket, setEventTicket] = useState<CandidateEventTicket>();

  async function initialLoad() {
    const eventTickets =
      await CandidateService.getCandidateEventTicketsAccepted();

    const groups: CandidateTicketGroup[] = [];

    for (var ticket of eventTickets) {
      var foundGroup = groups.filter(
        (e) => e.eventTicket.eventoBoletoID == ticket.eventoBoletoID
      )[0];

      if (foundGroup) {
        foundGroup.ticketsId.push(ticket.codigoBoleto);
      } else {
        groups.push({
          eventTicket: ticket,
          ticketsId: [ticket.codigoBoleto],
        });
      }
    }

    setTickets(groups);
    setIsLoading(false);
  }

  useEffect(() => {
    initialLoad();
  }, []);

  return (
    <div>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Eventos
        </Typography>
      </Box>

      {isLoading ? (
        <AppSpinner />
      ) : (
        <List>
          {tickets.length == 0 ? (
            <Typography
              textAlign="center"
              variant="h5"
              style={{ marginBottom: "5px", fontWeight: "bold" }}
            >
              No eventos disponible
            </Typography>
          ) : null}
          {tickets.map((el, index) => (
            <ListItem sx={{ mb: 2 }} key={index} disablePadding>
              <Box
                padding={2}
                sx={{
                  backgroundColor: "#0000000a",
                  width: "100%",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    style={{ marginBottom: "5px", fontWeight: "bold" }}
                  >
                    {el.eventTicket.eventoNombre.toUpperCase()}
                  </Typography>
                  <Typography style={{ marginBottom: "5px" }}>
                    {el.eventTicket.informacion}
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}>
                    Tickets:{" "}
                    <span>
                      {el.ticketsId.map((e, index) =>
                        index != el.ticketsId.length - 1 ? `${e}, ` : e
                      )}
                    </span>
                  </Typography>
                  <Box>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        setDialogTitle("Términos y condiciones");
                        setDialogDesc(
                          <div
                            dangerouslySetInnerHTML={{
                              __html: el.eventTicket!.terminosCondiciones!,
                            }}
                          ></div>
                        );
                        setOpenDialog(true);
                        setDialogButton(
                          <Button
                            onClick={() => {
                              setOpenDialog(false);
                            }}
                          >
                            Ok
                          </Button>
                        );
                      }}
                    >
                      Términos y condiciones
                    </Button>
                  </Box>

                  <Box mb={3}>
                    <Button
                      onClick={(e) => {
                        setOpenDialog(true);
                        setDialogTitle(el.eventTicket.eventoNombre);
                        setDialogDesc(
                          <img
                            style={{
                              maxWidth: "100%",
                              maxHeight: "350px",
                              height: "auto",
                            }}
                            src={el?.eventTicket.imagenURLWeb}
                            alt={el?.eventTicket.eventoNombre}
                          />
                        );
                        setDialogButton(
                          <Button onClick={() => setOpenDialog(false)}>
                            Ok
                          </Button>
                        );
                      }}
                      variant="contained"
                    >
                      Ver flyer
                    </Button>
                  </Box>

                  <Grid container spacing={2}>
                    {el.ticketsId.map((e, index) => (
                      <Grid item key={index} xs={12} md={3}>
                        <QRCode
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={e}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
              {/* <ListItem sx={{ backgroundColor: "#0000000a", display: "block" }}> */}
              {/* <Button
                  color="secondary"
                  sx={{ marginLeft: 2 }}
                  onClick={(e) => {
                    setEventTicket(el.eventTicket);
                    setEventModalOpen(true);
                  }}
                  variant="contained"
                >
                  Agregar mas moletas
                </Button> */}
              {/* </ListItem> */}
            </ListItem>
          ))}
        </List>
      )}
      <AddMoreTicketsModal
        eventTicket={eventTicket!}
        eventModalOpen={eventModalOpen}
        quantity={eventQuantity}
        setEventQuantity={(quantity: number) => {
          setEventQuantity(quantity);
        }}
        setEventModalOpen={(isOpen: boolean) => {
          setEventModalOpen(isOpen);
        }}
        savedChanges={async () => {
          setEventModalOpen(false);
          setIsLoading(true);
          await initialLoad();
        }}
      />
      <ConfirmationDialog
        maxWidth="md"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
    </div>
  );
}

interface AddMoreTicketsModalProps {
  eventTicket: CandidateEventTicket;
  eventModalOpen: boolean;
  quantity: number;
  setEventQuantity: (quantity: number) => void;
  setEventModalOpen: (isOpen: boolean) => void;
  savedChanges: () => void;
}

function AddMoreTicketsModal({
  eventTicket,
  eventModalOpen,
  quantity,
  setEventQuantity,
  setEventModalOpen,
  savedChanges,
}: AddMoreTicketsModalProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogDesc, setDialogDesc] = useState<string | JSX.Element>("");
  const [dialogButton, setDialogButton] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  function addTickets() {
    setDialogTitle("Confirmar");
    setDialogDesc(
      `¿Estas seguro de que deseas agregar ${quantity} boletas mas?`
    );
    setOpenDialog(true);
    setDialogButton([
      <Button
        variant="contained"
        color="customGrey"
        onClick={() => {
          setOpenDialog(false);
        }}
      >
        Cancelar
      </Button>,
      <Button
        variant="contained"
        color="secondary"
        onClick={async () => {
          setOpenDialog(false);
          setIsSaving(true);
          try {
            await CandidateService.acceptDenyAventTicket(
              eventTicket!.eventoBoletoID,
              Number(quantity)
            );
            setIsSaving(false);
            savedChanges();
          } catch (error) {
            setIsSaving(false);
            setOpenDialog(true);
            setDialogTitle("Error");
            setDialogDesc("Ha ocurrido un error. Por favor intente mas tarde");
            setDialogButton(
              <Button onClick={() => setOpenDialog(false)}>Ok</Button>
            );
          }
        }}
      >
        Aceptar
      </Button>,
    ]);
  }

  return (
    <Box>
      <Dialog fullWidth={true} maxWidth="md" open={eventModalOpen}>
        <DialogTitle>{eventTicket?.eventoNombre}</DialogTitle>
        <DialogContent>
          <Box>
            <Box mb={2}>
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "350px",
                  height: "auto",
                }}
                src={eventTicket?.imagenURLWeb}
                alt={eventTicket?.eventoNombre}
              />
            </Box>
            <Box display="flex" flexDirection="column">
              <Box mb={2} display="flex" alignItems="end">
                <Box mr={2}>
                  <Paragraph title="Boletas" required />
                  <AppTextField
                    style={{
                      margin: 0,
                    }}
                    inputProps={{
                      min: 1,
                    }}
                    type="number"
                    value={quantity}
                    onChange={(e) => {
                      if (e.target.value && Number(e.target.value) <= 0) {
                        e.target.value = "1";
                      }
                      setEventQuantity(Number(e.target.value));
                    }}
                  />
                </Box>

                <Button
                  onClick={() => {
                    addTickets();
                  }}
                  color="secondary"
                  variant="contained"
                  sx={{ mr: "10px", color: "white" }}
                >
                  Aceptar
                </Button>
              </Box>
              <Box display="flex" justifyContent="end">
                <Button
                  onClick={() => {
                    setEventModalOpen(false);
                  }}
                  color="customGrey"
                  variant="contained"
                  sx={{ mr: "10px", color: "white" }}
                >
                  Cerrar
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        maxWidth="sm"
        open={openDialog}
        title={dialogTitle}
        description={dialogDesc}
        buttons={dialogButton}
      />
      <LoadingModal isLoading={isSaving} loadingMessage="Guardando" />
    </Box>
  );
}
